<template>
    <div class="width-full h-screen fixed top-0 right-0 flex items-center justify-center">
        <Card class="flex p-4 flex-col gap-4 b-bottom" style="width: 500px">
            <div class="w-full flex justify-between items-center">
                <p class="font-semibold text-sm text-darkPurple">Delete Group</p>
                <span class="cursor-pointer" @click="closeModal">
                    <icon icon-name="red-close-icon" size="s" class-name="text-flame"/>
                </span>
            </div>

            <p class="w-full text-jet text-sm text-left">Are you sure you want to delete {{  groupName }} group?</p>

            <div class="w-full flex items-start justify-start">
                <button
                    class="w-auto h-auto text-flame text-sm font-semibold border-none outline-none"
                    :disabled="disableAddBtn"
                    @click="submitGroup"
                >
                    {{ disableAddBtn ? 'Deleting' : 'Yes, Delete' }}
                </button>

                <button
                    background-color="none"
                    class="w-auto h-auto ml-4 text-darkPurple text-sm font-semibold border-none outline-none"
                    :disabled="disableAddBtn"
                    @click="closeModal"
                >
                    No, Cancel
                </button>
            </div>
        </Card>    
    </div>
</template>
<script>
export default {
    name: "DeleteGroup",
    components: {
        Card: () => import("../../Card"),
    },
    props: {
        disableAddBtn: Boolean,
        groupName: String
    },
    data() {
        return {
            isSubmitting: false
        }
    },
    computed: {},
    methods: {
        closeModal(){
            this.$emit("cancel");
        },
        submitGroup(){
            this.$emit("delete-group")
        }
    },
};
</script>
<style scoped>
.width-full{
    width: 38.5rem;
    z-index: 9999 !important;
    background: rgba(0, 0, 0, .5) !important;
}
.b-bottom{
    border-bottom: 4px solid #F15A29 !important
}
</style>
